import { ReactNode } from "react"
import { create } from "zustand"

interface AppDialogState {
  leftImageUrl: string
  setLeftImageUrl: (url: string) => void
  isOpen: boolean
  setOpen: (b: boolean) => void
  close: () => void
  openDialog: (content: ReactNode, imageUrl?: string) => void
  content: ReactNode | null
  setContent: (node: ReactNode) => void
}

const defaultImg =
  "https://delivery.fanmade.ai/42_50f63826-dbea-4003-9809-e978326afbfe.png"

const appDialogStore = create<AppDialogState>((set) => ({
  leftImageUrl: defaultImg,
  setLeftImageUrl: (url: string) => set({ leftImageUrl: url }),
  isOpen: false,
  content: null,
  setContent: (node: ReactNode) => set({ content: node }),
  setOpen: (b: boolean) => set({ isOpen: b }),
  openDialog: (content: ReactNode, imageUrl?: string) =>
    set({
      isOpen: true,
      content: content,
      leftImageUrl: imageUrl || defaultImg,
    }),
  close: () => set({ isOpen: false }),
}))

export function useAppDialog() {
  const isOpen = appDialogStore((state) => state.isOpen)
  const setOpen = appDialogStore((state) => state.setOpen)
  const content = appDialogStore((state) => state.content)
  const setContent = appDialogStore((state) => state.setContent)
  const close = appDialogStore((state) => state.close)
  const openDialog = appDialogStore((state) => state.openDialog)
  const leftImageUrl = appDialogStore((state) => state.leftImageUrl)
  const setLeftImageUrl = appDialogStore((state) => state.setLeftImageUrl)

  return {
    leftImageUrl,
    setLeftImageUrl,
    isOpen,
    setOpen,
    content,
    setContent,
    close,
    openDialog,
  }
}
