import { Link } from "@remix-run/react"
import { buttonVariants } from "./ui/button"
import { Dialog, DialogContent, DialogClose } from "./ui/dialog"
import { useAppDialog } from "~/stores/app-dialog-store"
import { rsize } from "~/lib/utils"

const AppDialog = () => {
  const { content, isOpen, setOpen, leftImageUrl } = useAppDialog()

  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogContent className="max-w-4xl p-0">
        <div className="overflow-hidden rounded-none">
          <div className="grid md:grid-cols-2 gap-6">
            <div className="hidden md:block relative">
              <div className="text-center absolute w-full bottom-0 py-3 bg-black/40">
                <div className="brand text-5xl bg-gradient-to-r from-primary to-[#FECFB6] inline-block text-transparent bg-clip-text">
                  fanmade.ai
                </div>
              </div>
              <img
                alt="Fanmade AI companion"
                className="object-cover w-full h-full aspect-[3/4]"
                height="100%"
                src={rsize(leftImageUrl, 400)}
                width="100%"
              />
            </div>
            {content}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export interface DialogContext {
  title?: string
  description?: string
}

export const DailyMessageExceededDialogContent = () => {
  return (
    <div className="px-6 py-12 space-y-6">
      <h3 className="text-xl font-bold">Become premium</h3>
      <p>
        You have reach your daily message limit. Upgrade to Premium to continue
        chatting.
      </p>
      <DialogClose asChild>
        <Link to="/premium" className={buttonVariants()}>
          Upgrade to premium
        </Link>
      </DialogClose>
    </div>
  )
}

export const DailyImageExceededDialogContent = () => {
  return (
    <div className="px-6 py-12 space-y-6">
      <h3 className="text-xl font-bold">Become premium</h3>
      <p>
        You have reach your daily image limit. Upgrade to Premium to continue
        generating images.
      </p>
      <DialogClose asChild>
        <Link to="/premium" className={buttonVariants()}>
          Upgrade to premium
        </Link>
      </DialogClose>
    </div>
  )
}

export const PremiumFeatureDialogContent = () => {
  return (
    <div className="p-6 space-y-6">
      <div className="grid gap-6">
        <h3 className="text-xl font-bold">Premium feature</h3>
        <p>The feature you are trying to access is only for Premium members</p>
      </div>
      <DialogClose asChild>
        <Link to="/premium" className={buttonVariants()}>
          Upgrade to premium
        </Link>
      </DialogClose>
    </div>
  )
}

export const InsufficientCreditsDialogContent = (context?: DialogContext) => {
  return (
    <div className="p-6 space-y-6">
      <div className="grid gap-6">
        <h3 className="text-xl font-bold">
          {context?.title || "Insufficient credits"}
        </h3>
        <p>
          {context?.description ||
            "You account does not have enough credits for this feature."}
        </p>
      </div>
      <DialogClose asChild>
        <Link to="/premium" className={buttonVariants()}>
          Buy more credits
        </Link>
      </DialogClose>
    </div>
  )
}

export default AppDialog
